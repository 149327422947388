import { createApp } from "vue";
import "@/registerServiceWorker";
import "@/assets/tailwind.css";
import router from "@/router";
import store from "@/store";
import App from "@/App.vue";
import Aura from "@primevue/themes/aura";
import ToastPlugin from "vue-toast-notification";
import PrimeVue from "primevue/config";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import "vue-toast-notification/dist/theme-bootstrap.css";
import "flowbite";

createApp(App)
  .use(ToastPlugin, {
    position: "top-right",
  })
  .use(PrimeVue, {
    theme: {
      preset: Aura,
      options: {
        prefix: "personal",
        darkModeSelector: ".dark",
        cssLayer: false,
      },
    },
  })
  .use(store)
  .use(router)
  .component("DataTable", DataTable)
  .component("Column", Column)
  .mount("#app");
