import { hashMapFactory } from "@/utils/helper";
import { validateNationalID } from "@/utils/validations";

export const formSteps = [
  {
    position: "1",
    name: "Identificação & Contacto",
    current: true,
    last: false,
  },
  {
    position: "2",
    name: "Dados Pessoais",
    current: false,
    last: false,
  },
  {
    position: "3",
    name: "Candidatura",
    current: false,
    last: false,
  },
  {
    position: "4",
    name: "Dados do acesso",
    current: false,
    last: false,
  },
  {
    position: "5",
    name: "Confirmação dos dados",
    current: false,
    last: true,
  },
];

export const coursesSelectInputs = [
  {
    id: "grau",
    name: "grau_academico",
    placeholder: "Grau Académico",
    required: true,
    options: [
      { id: "Licenciatura", name: "Licenciatura" },
      { id: "Mestrado", name: "Mestrado" },
      { id: "Doutoramento", name: "Doutoramento" },
    ],
    optionPlaceholder: "name",
  },
  {
    id: "course1",
    name: "course1",
    placeholder: "Primeira opção",
    required: true,
    optionPlaceholder: "nome",
  },
  {
    id: "course2",
    name: "course2",
    placeholder: "Segunda opção",
    required: true,
    optionPlaceholder: "nome",
  },
];

export const personalDataTextInputs = [
  { placeholder: "Apelido", name: "apelido", type: "text", required: true },
  { placeholder: "Outros nomes", name: "nome", type: "text", required: true },
  {
    placeholder: "Nome do Pai",
    name: "nome_pai",
    type: "text",
    required: true,
  },
  {
    placeholder: "Nome da Mãe",
    name: "nome_mae",
    type: "text",
    required: true,
  },
  {
    placeholder: "Nr do Documento",
    name: "numero_documento",
    type: "text",
    required: true,
    validation: validateNationalID,
    msg: "BI inválido",
  },
  {
    placeholder: "Validade",
    name: "validade_documento",
    type: "date",
    required: true,
  },
  {
    placeholder: "Endereço da Moradia",
    name: "bairro_morada",
    type: "text",
    required: true,
  },
];

export const secSchoolInputs = [
  { placeholder: "Escola", name: "nome_escola", required: true, type: "text" },
  { placeholder: "Média", name: "media_final", required: true, type: "number" },
  {
    placeholder: "Nível Obtido",
    name: "nivel_obtido",
    required: true,
    type: "text",
  },
  { placeholder: "Duração", name: "duracao", required: true, type: "number" },
  {
    placeholder: "Ano de Conclusão",
    name: "ano_conclusao",
    required: true,
    type: "text",
  },
];

export const mStatueses = [
  { id: "s", value: "Solteiro", placeholder: "Solteiro" },
  { id: "c", value: "Casado", placeholder: "Casado" },
  { id: "v", value: "Viúvo", placeholder: "Viúvo" },
];

export const groups = [
  { id: "1", name: "12a Grupo A" },
  { id: "2", name: "12a Grupo B" },
  { id: "3", name: "12a Grupo C" },
  { id: "4", name: "Agrario" },
  { id: "5", name: "Artes Cénicas" },
  { id: "6", name: "Ciéncias de saúde" },
  { id: "7", name: "Formação de professores" },
  { id: "8", name: "Comercial" },
  { id: "9", name: "Industrial" },
  { id: "10", name: "Jornalismo" },
  { id: "11", name: "Outros" },
];

export const formData = {
  nome: "",
  apelido: "",
  nome_pai: "",
  nome_mae: "",
  sexo: "",
  email: "",
  data_nascimento: "",
  nacionalidade: null,
  provincia_nascimento: null,
  distrito_nascimento: null,
  pais_residenciaId: null,
  provincia_residenciaId: null,
  distrito_residenciaId: null,
  bairro_morada: "",
  contato_emergencia: "",
  contato: "",
  estado_civil: "",
  tipo_documentoId: null,
  numero_documento: "",
  comprovativosCandidato: [{ nome: "" }, { nome: "" }],
  validade_documento: "",
  cursoId_escolhido: [],
  nuit: null,
  scannerNuit: null,
  necessidade_educativaId: null,
  nr_casa: "",
  nome_escola: "",
  local: null,
  ano_conclusao: null,
  media_final: null,
  duracao: null,
  nivel_obtido: "",
  dispositivoElectronicoId: null,
  grau_academico: "",
  course1: "",
  course2: "",
  turno: "",
  senha: "",
};

export const residenceData = {};

export const selectedCoursesName = [];

export const dataNominals = () => {
  let bdData = hashMapFactory(residenceData.birthDistricts);
  let hCountries = hashMapFactory(countries);
  let dbCourses = hashMapFactory(courses);
  return [
    {
      item: "Nome",
      content: formData.nome,
    },
    {
      item: "Apelido",
      content: formData.apelido,
    },
    {
      item: "Nome do Pai",
      content: formData.nome_pai,
    },
    {
      item: "Nome da Mãe",
      content: formData.nome_mae,
    },
    {
      item: "Data de Nascimento",
      content: formData.data_nascimento,
    },
    {
      item: "Nacionalidade",
      content: hCountries[formData.nacionalidade].nome,
    },
    {
      item: "Naturalidade",
      content: bdData[formData.distrito_nascimento].nome,
    },
    {
      item: "Género",
      content: formData.sexo,
    },
    {
      item: "Estado Civil",
      content: formData.estado_civil,
    },
    {
      item: "Residência",
      content: `${formData.bairro_morada} `,
    },
    {
      item: "Nr de Doc. de Identificaçao",
      content: formData.numero_documento,
    },
    {
      item: "Cursos a que se Candidata",
      content: `${dbCourses[formData.course1].nome},\n ${
        dbCourses[formData.course2].nome
      }`,
    },
  ];
};

export const postGraduateDocs = {
  scannerCertificado: {
    name: "Certificado",
    id: "scannerCertificado",
    labelId: "certificado",
    required: true,
  },
  scannerCartaMotivacao: {
    name: "Carta de Motivação",
    id: "scannerCartaMotivacao",
    labelId: "cm",
    required: false,
  },
  scannerCV: {
    name: "CV",
    id: "scannerCV",
    labelId: "cv",
    required: true,
  },
};

export const courses = [
  {
    id: 1,
    nome: "Engenharia Informática e de Computadores",
    abreviatura: "EIC",
    dptId: 5,
    codigo: 104,
    grauacademico: "Licenciatura",
    createdAt: "2024-01-13T17:24:43.081Z",
  },
  {
    id: 2,
    nome: "Engenharia Civil",
    abreviatura: "ECI",
    dptId: 3,
    codigo: 101,
    grauacademico: "Licenciatura",
    createdAt: "2024-01-13T17:24:43.081Z",
  },
  {
    id: 3,
    nome: "Engenharia Electrónica e de Telecomunicações",
    abreviatura: "EET",
    dptId: 5,
    codigo: 103,
    grauacademico: "Licenciatura",
    createdAt: "2024-01-13T17:24:43.081Z",
  },
  {
    id: 4,
    nome: "Agronomia",
    abreviatura: "AGR",
    dptId: 6,
    codigo: 105,
    grauacademico: "Licenciatura",
    createdAt: "2024-01-13T17:24:43.081Z",
  },
  {
    id: 5,
    nome: "Contabilidade e Administração Pública",
    abreviatura: "CAP",
    dptId: 2,
    codigo: 108,
    grauacademico: "Licenciatura",
    createdAt: "2024-01-13T17:24:43.081Z",
  },
  {
    id: 6,
    nome: "Direito",
    abreviatura: "DIR",
    dptId: 2,
    codigo: 107,
    grauacademico: "Licenciatura",
    createdAt: "2024-01-13T17:24:43.081Z",
  },
  {
    id: 7,
    nome: "Análises Clínicas e Saúde Pública",
    abreviatura: "ACSP",
    dptId: 4,
    codigo: 106,
    grauacademico: "Licenciatura",
    createdAt: "2024-01-13T17:24:43.081Z",
  },
  {
    id: 8,
    nome: "Geologia e Minas",
    abreviatura: "GEM",
    dptId: 3,
    codigo: 102,
    grauacademico: "Licenciatura",
    createdAt: "2024-01-13T17:24:43.081Z",
  },
  {
    id: 17,
    nome: "Engenharia Civil – Especialização em Hidráulica e Infraestruturas Rodoviárias",
    abreviatura: "",
    dptId: 3,
    codigo: 201,
    grauacademico: "Mestrado",
    createdAt: "2024-01-13T17:24:43.081Z",
  },
  {
    id: 18,
    nome: "Gestão Estratégica de Recursos Humanos ",
    abreviatura: "",
    dptId: 2,
    codigo: 203,
    grauacademico: "Mestrado",
    createdAt: "2024-01-13T17:24:43.081Z",
  },
  {
    id: 19,
    nome: "Saúde Pública",
    abreviatura: "",
    dptId: 4,
    codigo: 202,
    grauacademico: "Mestrado",
    createdAt: "2024-01-13T17:24:43.081Z",
  },
  {
    id: 20,
    nome: "Desenvolvimento Humano e Educação",
    abreviatura: "",
    dptId: 1,
    codigo: 204,
    grauacademico: "Mestrado",
    createdAt: "2024-01-13T17:24:43.081Z",
  },
  {
    id: 21,
    nome: "Ciências de Educação – Especialização em Inovação e Currículo",
    abreviatura: "",
    dptId: 1,
    codigo: 302,
    grauacademico: "Doutoramento",
    createdAt: "2024-01-13T17:24:43.081Z",
  },
  {
    id: 22,
    nome: "Ciências de Educação – Especialização em Educação Inclusiva e Pedagogia Diferenciada",
    abreviatura: "",
    dptId: 1,
    codigo: 303,
    grauacademico: "Doutoramento",
    createdAt: "2024-01-13T17:24:43.081Z",
  },
  {
    id: 23,
    nome: "Engenharia Civil - Especialização em Infraestruturas Rodoviárias e Vias-Férreas",
    abreviatura: null,
    dptId: 3,
    codigo: 301,
    grauacademico: "Doutoramento",
    createdAt: "2024-03-03T13:56:41.597Z",
  },
  {
    id: 24,
    nome: "Economia e Gestão de Empresas",
    abreviatura: "EGE",
    dptId: 2,
    codigo: 109,
    grauacademico: "Licenciatura",
    createdAt: "2024-03-19T12:26:13.397Z",
  },
  {
    id: 25,
    nome: "Gestão de Recursos Humanos",
    abreviatura: "GRH",
    dptId: 2,
    codigo: 1,
    grauacademico: "Licenciatura",
    createdAt: "2024-11-27T10:18:56.228Z",
  },
  {
    id: 26,
    nome: "Gestão de Recursos Naturais e Ambiente",
    abreviatura: "GRNA",
    dptId: 3,
    codigo: 1,
    grauacademico: "Licenciatura",
    createdAt: "2024-11-27T10:20:21.515Z",
  },
  {
    id: 27,
    nome: "Transformação Digital",
    abreviatura: "TD",
    dptId: 1,
    codigo: 1,
    grauacademico: "Mestrado",
    createdAt: "2024-11-27T10:21:42.498Z",
  },
];

export const docTypes = [
  {
    id: 1,
    nome: "Bilhete de Identificação",
  },
  {
    id: 2,
    nome: "Carta de Condução",
  },
  {
    id: 3,
    nome: "Passaporte",
  },
  {
    id: 4,
    nome: "DIRE",
  },
  {
    id: 5,
    nome: "Cédula",
  },
];

export const countries = [
  { id: 1, nome: "Afeganistão", code: "AF" },
  { id: 2, nome: "Ilhas Åland", code: "AX" },
  { id: 3, nome: "Albânia", code: "AL" },
  { id: 4, nome: "Argélia", code: "DZ" },
  { id: 5, nome: "Samoa Americana", code: "AS" },
  { id: 6, nome: "Andorra", code: "AD" },
  { id: 7, nome: "Angola", code: "AO" },
  { id: 8, nome: "Anguilla", code: "AI" },
  { id: 9, nome: "Antártica", code: "AQ" },
  { id: 10, nome: "Antígua e Barbuda", code: "AG" },
  { id: 11, nome: "Argentina", code: "AR" },
  { id: 12, nome: "Armênia", code: "AM" },
  { id: 13, nome: "Aruba", code: "AW" },
  { id: 14, nome: "Austrália", code: "AU" },
  { id: 15, nome: "Áustria", code: "AT" },
  { id: 16, nome: "Azerbaijão", code: "AZ" },
  { id: 17, nome: "Bahamas", code: "BS" },
  { id: 18, nome: "Bahrain", code: "BH" },
  { id: 19, nome: "Bangladesh", code: "BD" },
  { id: 20, nome: "Barbados", code: "BB" },
  { id: 21, nome: "Bielorrússia", code: "BY" },
  { id: 22, nome: "Bélgica", code: "BE" },
  { id: 23, nome: "Belize", code: "BZ" },
  { id: 24, nome: "Benin", code: "BJ" },
  { id: 25, nome: "Bermudas", code: "BM" },
  { id: 26, nome: "Butão", code: "BT" },
  { id: 27, nome: "Bolívia", code: "BO" },
  { id: 28, nome: "Bósnia e Herzegovina", code: "BA" },
  { id: 29, nome: "Botsuana", code: "BW" },
  { id: 30, nome: "Ilha Bouvet", code: "BV" },
  { id: 31, nome: "Brasil", code: "BR" },
  { id: 32, nome: "Território Britânico do Oceano Índico", code: "IO" },
  { id: 33, nome: "Brunei Darussalam", code: "BN" },
  { id: 34, nome: "Bulgária", code: "BG" },
  { id: 35, nome: "Burkina Faso", code: "BF" },
  { id: 36, nome: "Burundi", code: "BI" },
  { id: 37, nome: "Camboja", code: "KH" },
  { id: 38, nome: "Camarões", code: "CM" },
  { id: 39, nome: "Canadá", code: "CA" },
  { id: 40, nome: "Cabo Verde", code: "CV" },
  { id: 41, nome: "Ilhas Cayman", code: "KY" },
  { id: 42, nome: "República Centro-Africana", code: "CF" },
  { id: 43, nome: "Chade", code: "TD" },
  { id: 44, nome: "Chile", code: "CL" },
  { id: 45, nome: "China", code: "CN" },
  { id: 46, nome: "Ilha do Natal", code: "CX" },
  { id: 47, nome: "Ilhas Cocos (Keeling)", code: "CC" },
  { id: 48, nome: "Colômbia", code: "CO" },
  { id: 49, nome: "Comores", code: "KM" },
  { id: 50, nome: "Congo", code: "CG" },
  { id: 51, nome: "República Democrática do Congo", code: "CD" },
  { id: 52, nome: "Ilhas Cook", code: "CK" },
  { id: 53, nome: "Costa Rica", code: "CR" },
  { id: 54, nome: "Costa do Marfim", code: "CI" },
  { id: 55, nome: "Croácia", code: "HR" },
  { id: 56, nome: "Cuba", code: "CU" },
  { id: 57, nome: "Chipre", code: "CY" },
  { id: 58, nome: "República Checa", code: "CZ" },
  { id: 59, nome: "Dinamarca", code: "DK" },
  { id: 60, nome: "Djibouti", code: "DJ" },
  { id: 61, nome: "Dominica", code: "DM" },
  { id: 62, nome: "República Dominicana", code: "DO" },
  { id: 63, nome: "Equador", code: "EC" },
  { id: 64, nome: "Egito", code: "EG" },
  { id: 65, nome: "El Salvador", code: "SV" },
  { id: 66, nome: "Guiné Equatorial", code: "GQ" },
  { id: 67, nome: "Eritreia", code: "ER" },
  { id: 68, nome: "Estônia", code: "EE" },
  { id: 69, nome: "Etiópia", code: "ET" },
  { id: 70, nome: "Ilhas Malvinas (Falkland)", code: "FK" },
  { id: 71, nome: "Ilhas Faroe", code: "FO" },
  { id: 72, nome: "Fiji", code: "FJ" },
  { id: 73, nome: "Finlândia", code: "FI" },
  { id: 74, nome: "França", code: "FR" },
  { id: 75, nome: "Guiana Francesa", code: "GF" },
  { id: 76, nome: "Polinésia Francesa", code: "PF" },
  { id: 77, nome: "Territórios Franceses do Sul", code: "TF" },
  { id: 78, nome: "Gabão", code: "GA" },
  { id: 79, nome: "Gâmbia", code: "GM" },
  { id: 80, nome: "Geórgia", code: "GE" },
  { id: 81, nome: "Alemanha", code: "DE" },
  { id: 82, nome: "Gana", code: "GH" },
  { id: 83, nome: "Gibraltar", code: "GI" },
  { id: 84, nome: "Grécia", code: "GR" },
  { id: 85, nome: "Groenlândia", code: "GL" },
  { id: 86, nome: "Granada", code: "GD" },
  { id: 87, nome: "Guadalupe", code: "GP" },
  { id: 88, nome: "Guam", code: "GU" },
  { id: 89, nome: "Guatemala", code: "GT" },
  { id: 90, nome: "Guernsey", code: "GG" },
  { id: 91, nome: "Guiné", code: "GN" },
  { id: 92, nome: "Guiné-Bissau", code: "GW" },
  { id: 93, nome: "Guiana", code: "GY" },
  { id: 94, nome: "Haiti", code: "HT" },
  { id: 95, nome: "Ilha Heard e Ilhas McDonald", code: "HM" },
  { id: 96, nome: "Santa Sé (Estado da Cidade do Vaticano)", code: "VA" },
  { id: 97, nome: "Honduras", code: "HN" },
  { id: 98, nome: "Hong Kong", code: "HK" },
  { id: 99, nome: "Hungria", code: "HU" },
  { id: 100, nome: "Islândia", code: "IS" },
  { id: 101, nome: "Índia", code: "IN" },
  { id: 102, nome: "Indonésia", code: "ID" },
  { id: 103, nome: "Irã, República Islâmica do", code: "IR" },
  { id: 104, nome: "Iraque", code: "IQ" },
  { id: 105, nome: "Irlanda", code: "IE" },
  { id: 106, nome: "Ilha de Man", code: "IM" },
  { id: 107, nome: "Israel", code: "IL" },
  { id: 108, nome: "Itália", code: "IT" },
  { id: 109, nome: "Jamaica", code: "JM" },
  { id: 110, nome: "Japão", code: "JP" },
  { id: 111, nome: "Jersey", code: "JE" },
  { id: 112, nome: "Jordânia", code: "JO" },
  { id: 113, nome: "Cazaquistão", code: "KZ" },
  { id: 114, nome: "Quênia", code: "KE" },
  { id: 115, nome: "Kiribati", code: "KI" },
  { id: 116, nome: "República Popular Democrática da Coreia", code: "KP" },
  { id: 117, nome: "República da Coreia", code: "KR" },
  { id: 118, nome: "Kuwait", code: "KW" },
  { id: 119, nome: "Quirguistão", code: "KG" },
  { id: 120, nome: "República Democrática Popular do Laos", code: "LA" },
  { id: 121, nome: "Letônia", code: "LV" },
  { id: 122, nome: "Líbano", code: "LB" },
  { id: 123, nome: "Lesoto", code: "LS" },
  { id: 124, nome: "Libéria", code: "LR" },
  { id: 125, nome: "Jamahiriya Árabe da Líbia", code: "LY" },
  { id: 126, nome: "Liechtenstein", code: "LI" },
  { id: 127, nome: "Lituânia", code: "LT" },
  { id: 128, nome: "Luxemburgo", code: "LU" },
  { id: 129, nome: "Macau", code: "MO" },
  { id: 130, nome: "Macedônia, Antiga República Iugoslava da", code: "MK" },
  { id: 131, nome: "Madagascar", code: "MG" },
  { id: 132, nome: "Malawi", code: "MW" },
  { id: 133, nome: "Malásia", code: "MY" },
  { id: 134, nome: "Maldivas", code: "MV" },
  { id: 135, nome: "Mali", code: "ML" },
  { id: 136, nome: "Malta", code: "MT" },
  { id: 137, nome: "Ilhas Marshall", code: "MH" },
  { id: 138, nome: "Martinica", code: "MQ" },
  { id: 139, nome: "Mauritânia", code: "MR" },
  { id: 140, nome: "Maurícia", code: "MU" },
  { id: 141, nome: "Mayotte", code: "YT" },
  { id: 142, nome: "México", code: "MX" },
  { id: 143, nome: "Micronésia, Estados Federados da", code: "FM" },
  { id: 144, nome: "Moldávia, República da", code: "MD" },
  { id: 145, nome: "Mônaco", code: "MC" },
  { id: 146, nome: "Mongólia", code: "MN" },
  { id: 147, nome: "Montserrat", code: "MS" },
  { id: 148, nome: "Marrocos", code: "MA" },
  { id: 149, nome: "Moçambique", code: "MZ" },
  { id: 150, nome: "Myanmar", code: "MM" },
  { id: 151, nome: "Namíbia", code: "NA" },
  { id: 152, nome: "Nauru", code: "NR" },
  { id: 153, nome: "Nepal", code: "NP" },
  { id: 154, nome: "Países Baixos", code: "NL" },
  { id: 155, nome: "Antilhas Holandesas", code: "AN" },
  { id: 156, nome: "Nova Caledônia", code: "NC" },
  { id: 157, nome: "Nova Zelândia", code: "NZ" },
  { id: 158, nome: "Nicarágua", code: "NI" },
  { id: 159, nome: "Níger", code: "NE" },
  { id: 160, nome: "Nigéria", code: "NG" },
  { id: 161, nome: "Niue", code: "NU" },
  { id: 162, nome: "Ilha Norfolk", code: "NF" },
  { id: 163, nome: "Ilhas Marianas do Norte", code: "MP" },
  { id: 164, nome: "Noruega", code: "NO" },
  { id: 165, nome: "Omã", code: "OM" },
  { id: 166, nome: "Paquistão", code: "PK" },
  { id: 167, nome: "Palau", code: "PW" },
  { id: 168, nome: "Território Palestino Ocupado", code: "PS" },
  { id: 169, nome: "Panamá", code: "PA" },
  { id: 170, nome: "Papua-Nova Guiné", code: "PG" },
  { id: 171, nome: "Paraguai", code: "PY" },
  { id: 172, nome: "Peru", code: "PE" },
  { id: 173, nome: "Filipinas", code: "PH" },
  { id: 174, nome: "Pitcairn", code: "PN" },
  { id: 175, nome: "Polônia", code: "PL" },
  { id: 176, nome: "Portugal", code: "PT" },
  { id: 177, nome: "Porto Rico", code: "PR" },
  { id: 178, nome: "Qatar", code: "QA" },
  { id: 179, nome: "Reunião", code: "RE" },
  { id: 180, nome: "Romênia", code: "RO" },
  { id: 181, nome: "Federação Russa", code: "RU" },
  { id: 182, nome: "Ruanda", code: "RW" },
  { id: 183, nome: "Santa Helena", code: "SH" },
  { id: 184, nome: "São Cristóvão e Neves", code: "KN" },
  { id: 185, nome: "Santa Lúcia", code: "LC" },
  { id: 186, nome: "São Pedro e Miquelon", code: "PM" },
  { id: 187, nome: "São Vicente e Granadinas", code: "VC" },
  { id: 188, nome: "Samoa", code: "WS" },
  { id: 189, nome: "San Marino", code: "SM" },
  { id: 190, nome: "São Tomé e Príncipe", code: "ST" },
  { id: 191, nome: "Arábia Saudita", code: "SA" },
  { id: 192, nome: "Senegal", code: "SN" },
  { id: 193, nome: "Sérvia e Montenegro", code: "CS" },
  { id: 194, nome: "Seychelles", code: "SC" },
  { id: 195, nome: "Serra Leoa", code: "SL" },
  { id: 196, nome: "Cingapura", code: "SG" },
  { id: 197, nome: "Eslováquia", code: "SK" },
  { id: 198, nome: "Eslovênia", code: "SI" },
  { id: 199, nome: "Ilhas Salomão", code: "SB" },
  { id: 200, nome: "Somália", code: "SO" },
  { id: 201, nome: "África do Sul", code: "ZA" },
  { id: 202, nome: "Geórgia do Sul e Ilhas Sandwich do Sul", code: "GS" },
  { id: 203, nome: "Espanha", code: "ES" },
  { id: 204, nome: "Sri Lanka", code: "LK" },
  { id: 205, nome: "Sudão", code: "SD" },
  { id: 206, nome: "Suriname", code: "SR" },
  { id: 207, nome: "Svalbard e Jan Mayen", code: "SJ" },
  { id: 208, nome: "Suazilândia", code: "SZ" },
  { id: 209, nome: "Suécia", code: "SE" },
  { id: 210, nome: "Suíça", code: "CH" },
  { id: 211, nome: "República Árabe da Síria", code: "SY" },
  { id: 212, nome: "Taiwan, Província da China", code: "TW" },
  { id: 213, nome: "Tajiquistão", code: "TJ" },
  { id: 214, nome: "República Unida da Tanzânia", code: "TZ" },
  { id: 215, nome: "Tailândia", code: "TH" },
  { id: 216, nome: "Timor-Leste", code: "TL" },
  { id: 217, nome: "Togo", code: "TG" },
  { id: 218, nome: "Tokelau", code: "TK" },
  { id: 219, nome: "Tonga", code: "TO" },
  { id: 220, nome: "Trinidade e Tobago", code: "TT" },
  { id: 221, nome: "Tunísia", code: "TN" },
  { id: 222, nome: "Turquia", code: "TR" },
  { id: 223, nome: "Turcomenistão", code: "TM" },
  { id: 224, nome: "Ilhas Turks e Caicos", code: "TC" },
  { id: 225, nome: "Tuvalu", code: "TV" },
  { id: 226, nome: "Uganda", code: "UG" },
  { id: 227, nome: "Ucrânia", code: "UA" },
  { id: 228, nome: "Emirados Árabes Unidos", code: "AE" },
  { id: 229, nome: "Reino Unido", code: "GB" },
  { id: 230, nome: "Estados Unidos", code: "US" },
  { id: 231, nome: "Ilhas Menores Distantes dos Estados Unidos", code: "UM" },
  { id: 232, nome: "Uruguai", code: "UY" },
  { id: 233, nome: "Uzbequistão", code: "UZ" },
  { id: 234, nome: "Vanuatu", code: "VU" },
  { id: 235, nome: "Venezuela", code: "VE" },
  { id: 236, nome: "Vietnã", code: "VN" },
  { id: 237, nome: "Ilhas Virgens Britânicas", code: "VG" },
  { id: 238, nome: "Ilhas Virgens, EUA", code: "VI" },
  { id: 239, nome: "Wallis e Futuna", code: "WF" },
  { id: 240, nome: "Saara Ocidental", code: "EH" },
  { id: 241, nome: "Iémen", code: "YE" },
  { id: 242, nome: "Zâmbia", code: "ZM" },
  { id: 243, nome: "Zimbábue", code: "ZW" },
];
