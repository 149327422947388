import { formatDate } from "@/utils/helper";
export const timeline = [
  {
    title: "Cadastro de Dados de Candidatura",
    details:
      "Cadastro de dados pessoais, dados de identificação e dados de candidatura",
    on: "Feito em 12-08-2024",
    passed: true,
    current: false,
  },
  {
    title: "Submissão de comprovotivo de pagamento",
    details:
      "Submissão da foto do talão de depósito que terá recebido do banco ao pagar a taxa de inscrição",
    on: "Em andamento",
    passed: false,
    current: true,
  },
  {
    title: "Aprovação da candidatura",
    details:
      "O seu comprovotaivo de pagamento de pagamento está sendo analisado, fique atento ao seu email ",
    on: "Termine a fase anterior",
    passed: false,
    current: false,
  },
];

export const timelineData = {};
export const candidateData = {};

export const timelineFactory = (payload) => {
  let timelineData = [];
  for (let index = 0; index < 3; index++) {
    const data = {
      title:
        index === 0
          ? "Cadastro de Dados de Candidatura"
          : "Submissão de comprovotivo de pagamento",
      details:
        index === 0
          ? "Cadastro de dados pessoais, dados de identificação e dados de candidatura"
          : index === 1
          ? "Submissão da foto do talão de depósito que terá recebido do banco ao pagar a taxa de inscrição"
          : index === 2 && payload.isAproved
          ? "Parabéns, a validação da sua candidatura foi um sucesso, visite seu e-mail para saber mais"
          : "O seu comprovotaivo de pagamento de pagamento está sendo analisado, fique atento ao seu email",
      on:
        index === 0
          ? formatDate(payload.createdAt)
          : index === 1
          ? !payload.hasPaymentsCompleted
            ? `Em andamento`
            : "Concluído"
          : !payload.isAproved
          ? !payload.hasPaymentsCompleted
            ? `Termine a fase anterior para habilitar`
            : `Aguardando pela validação`
          : `Concluído em ${formatDate(payload.updatedAt)}`,
      passed:
        index === 0
          ? true
          : index === 1
          ? payload.hasPaymentsCompleted
          : payload.isAproved,
      current:
        index === 0
          ? false
          : index === 1
          ? payload.hasPaymentsCompleted
          : payload.isAproved,
    };
    timelineData.push(data);
  }
  return timelineData;
};
export const showDrawer = (routeName) => {
  const prohibited = ["login", "passwordConfirm", "thankYou"];
  return !prohibited.includes(routeName);
};

export const tableCols = [
  { field: "name", header: "Nome" },
  { field: "candidateCode", header: "Cod. Candidato" },
  { field: "contact", header: "Contacto" },
  { field: "regime", header: "Regime" },
  { field: "course1", header: "opt1" },
  { field: "course2", header: "opt2" },
  { field: "hasPayed", header: "Estado de Pagamento" },
  { field: "isAproved", header: "Estado da candidatura" },
];
