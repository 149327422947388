// Javascript RegExp Reference
//  /abc/	A sequence of characters
//  /[abc]/	Any character from a set of characters
//  /[^abc]/	Any character not in a set of characters
//  /[0-9]/	Any character in a range of characters
//  /x+/	One or more occurrences of the pattern x
//  /x+?/	One or more occurrences, nongreedy
//  /x*/	Zero or more occurrences
//  /x?/	Zero or one occurrence
//  /x{2,4}/	Two to four occurrences
//  /(abc)/	A group
//  /a|b|c/	Any one of several patterns
//  /\d/	Any digit character
// /\w/	An alphanumeric character (“word character”)
//  /\s/	Any whitespace character
//  /./	Any character except newlines
//  /\b/	A word boundary
//  /^/	Start of input
//  /$/	End of input

import { getBase64Size } from "@/utils/helper";

export const validatePassword = (password) => {
  const regex = /^(?=.*[A-Za-z])(?=.*\d).{6,}$/;
  return regex.test(password);
};
export const validateNumbers = (text) => {
  let reg = new RegExp("^[0-9]+$");
  return reg.test(text);
};

export const notNullable = (text) => {
  return text !== "";
};

export const validateDecimals = (text) => {
  //"[1-9]*\.[0-9]"
  let reg = new RegExp("^[1-9]+.[0-9]+$");
  return text.match(reg);
};

export const validateNationalID = (text) => {
  let reg = new RegExp("^[0-9]{12}[A-Z]$");
  return reg.test(text);
};

export const validateNUIT = (text) => {
  let reg = new RegExp("^[0-9]{9}$");
  return reg.test(text);
};

export const validadeNationalPhoneNumbers = (text) => {
  let reg = new RegExp("^8[2-7][0-9]{7}$");
  validateNumbers(text);
  return text.match(reg);
};

export const vodacomPhoneNumbers = (text) => {
  let reg = new RegExp("^8[4-5][0-9]{7}$");
  validateNumbers(text);
  return text.match(reg);
};

export const validateEmail = (text) => {
  let reg = new RegExp(
    "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
  );
  return reg.test(text);
};

export const validationAction = (component, callback) => {
  let result = callback(component.value);
  if (!result) {
    component.style = "border-color:red";
    return false;
  } else component.style = "border-color:inherit";
  return true;
};

export const canUploadFile = (fileInput, size) => {
  return getBase64Size(fileInput) <= size;
};

export const submitForm = (e, form) => {
  e.preventDefault();
  performSubmition(form);
};

export const performSubmition = (form) => {
  let formsNode = form.elements;
  for (let i = 0; i < formsNode.length; i++) {
    if (formsNode[i].style.borderColor == "red") return false;
  }
  form.submit();
  return true;
};
