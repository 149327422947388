import { createStore } from "vuex";
import { authGet, getProvinces } from "@/utils/CandidaturasAPI";
import { jwtDecode } from "jwt-decode";
import { getCandidates } from "@/utils/CommonFunctions";
import { courses, docTypes } from "@/utils/inputDataStandards";

export const CAST_ERROR = "CAST_ERROR";
export const REMOVE_ERROR = "REMOVE_ERROR";
export const FILL_PROVINCES = "FILL_PROVINCES";
export const FETCH_PROVINCES = "FETCH_PROVINCES";
export const FETCH_DOCTYPES = "FETCH_DOCTYPES";
export const FETCH_PERIOD = "FETCH_PERIOD";
export const FETCH_CANDIDATES = "FETCH_CANDIDATES";
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT = "LOGOUT";
export const DONE_LOADING = "DONE_LOADING";
export const SET_CANDIDATES = "SET_CANDIDATES";
export const SET_CURRENT_PERIOD = "CURRENT_PERIOD";
export const SET_LOADING = "SET_LOADING";
export const SET_RECENT_CANDIDATE = "SET_RECENT_CANDIDATE";
export const SET_TIMELINE = "SET_TIMELINE";
export const FILTER_CAT_COURSES = "FILTER_CAT_COURSES";
export const SET_TO_UPDATE_CAND = "SET_TO_UPDATE_CAND";

export const state = () => {
  return {
    hasErrors: {},
    courses,
    docTypes,
    provinces: [],
    currentPeriod: {},
    candidates: [],
    recentCandidate: {},
    isLoggedIn: false,
    isLoading: false,
    setToUpdateCandidate: false,
    candidateTimeline: {},
    token: null,
    userData: {},
    lCourses: [],
    mCourses: [],
    dCourses: [],
  };
};

export const mutations = {
  [CAST_ERROR](state, error) {
    state.hasErrors[error.id] = error;
  },
  [FILL_PROVINCES](state, payload) {
    state.provinces = payload;
  },
  [REMOVE_ERROR](state, errorId) {
    delete state.hasErrors[errorId];
  },
  [SET_RECENT_CANDIDATE](state, payload) {
    state.recentCandidate = payload;
  },
  [SET_TIMELINE](state, payload) {
    state.candidateTimeline = payload;
  },
  [SET_LOADING](state) {
    state.isLoading = true;
  },
  [SET_CANDIDATES](state, payload) {
    state.candidates = payload;
  },
  [SET_CURRENT_PERIOD](state, payload) {
    state.currentPeriod = payload;
  },
  [SET_TO_UPDATE_CAND](state) {
    state.setToUpdateCandidate = true;
  },
  [DONE_LOADING](state) {
    state.isLoading = false;
  },
  [LOGIN_USER](state, payload) {
    state.isLoggedIn = true;
    state.token = payload;
    state.userData = jwtDecode(payload);
  },
  [LOGOUT](state) {
    state.isLoggedIn = false;
    state.token = "";
    state.userData = {};
  },
  [FILTER_CAT_COURSES](state) {
    state.courses.forEach((course) => {
      if (course.grauacademico === "Licenciatura") state.lCourses.push(course);
      if (course.grauacademico === "Mestrado") state.mCourses.push(course);
      if (course.grauacademico === "Doutoramento") state.dCourses.push(course);
    });
  },
};

export const actions = {
  [FETCH_PROVINCES](context) {
    getProvinces().then((r) => {
      context.commit(FILL_PROVINCES, r.data);
    });
  },
  async [FETCH_PERIOD](context) {
    let payload = await authGet("/abertura_candidatura/periodo/ativo");
    context.commit(SET_CURRENT_PERIOD, payload.data);
  },
  async [FETCH_CANDIDATES](context) {
    let payload = await getCandidates();
    context.commit(SET_CANDIDATES, payload);
  },
};

export default createStore({
  state,
  mutations,
  actions,
  getters: {},
  modules: {},
});
