import { hashMapFactory } from "@/utils/helper";
import { courses } from "@/utils/inputDataStandards";
import { authGet } from "@/utils/CandidaturasAPI";

export const getCourse = (courseId) => {
  let coursesObj = hashMapFactory(courses);
  return coursesObj[courseId].nome;
};

export const getProgramByCourse = (courseId) => {
  let coursesObj = hashMapFactory(courses);
  return coursesObj[courseId].grauacademico;
};

export const getCandidates = async () => {
  let r = await authGet("/candidatura");
  r.data;
  let candidates = [];
  r.data.forEach((data) => {
    let selectedCourses = data.inscricao_curso_estudante[0];
    let validatedCourse = selectedCourses.cursoId_validado;
    let payload = {
      id: data.id,
      name: data.nome,
      candidateCode: data.codigo_candidato,
      contact: data.contato,
      sosContact: data.contato_emergencia,
      email: data.email,
      course1: getCourse(selectedCourses.cursoId_escolhido[0]),
      course2: getCourse(selectedCourses.cursoId_escolhido[1]),
      courses: [
        {
          id: selectedCourses.cursoId_escolhido[0],
          name: getCourse(selectedCourses.cursoId_escolhido[0]),
        },
        {
          id: selectedCourses.cursoId_escolhido[1],
          name: getCourse(selectedCourses.cursoId_escolhido[1]),
        },
      ],
      program: getProgramByCourse(selectedCourses.cursoId_escolhido[0]),
      regime: data.periodo_inscricaoId === 1 ? "Laboral" : "Pós Laboral",
      hasPaymentsCompleted: hasPayed(data.Comprovativos),
      hasInscricaoPayed: hasInscricaoPayed(data.Comprovativos),
      proofs: hashMapFactory(data.Comprovativos, "tipocomprovativo"),
      isAproved: validatedCourse !== null,
    };
    candidates.push(payload);
  });
  return candidates;
};

export const hasPayed = (payload) => {
  let proofs = hashMapFactory(payload, "tipocomprovativo");
  return (
    proofs.scannerPagamentoInscricao !== undefined &&
    proofs.scannerPagamentoMatricula !== undefined
  );
};
export const hasInscricaoPayed = (payload) => {
  let proofs = hashMapFactory(payload, "tipocomprovativo");
  return proofs.scannerPagamentoInscricao !== undefined;
};

export const getCandidateDocs = async (candidateId) => {
  const docs = {};
  let candidateDocs = await authGet(
    `/candidatura/comprovativos/${candidateId}`
  );

  candidateDocs.data.forEach((file) => {
    if (file.tipocomprovativo === "scannerPagamentoInscricao")
      docs.scannerPagamentoInscricao = {
        id: "scannerPagamentoInscricao",
        name: file.name,
        file: `data:image/jpeg;base64,${file.content}`,
      };
    if (file.tipocomprovativo === "scannerPagamentoMatricula")
      docs.scannerPagamentoMatricula = {
        id: "scannerPagamentoMatricula",
        name: file.name,
        file: `data:image/jpeg;base64,${file.content}`,
      };
    if (file.tipocomprovativo === "scannerBI")
      docs.docId = {
        id: "scannerBI",
        name: file.name,
        file: `data:image/jpeg;base64,${file.content}`,
      };
    if (file.tipocomprovativo === "scannerCertificado")
      docs.certificate = {
        id: "scannerCertificado",
        name: file.name,
        file: `data:application/pdf;base64,${file.content}`,
      };
    if (file.tipocomprovativo === "scannerCartaMotivacao")
      docs.motivationLetter = {
        id: "scannerCartaMotivacao",
        name: file.name,
        file: `data:application/pdf;base64,${file.content}`,
      };
    if (file.tipocomprovativo === "scannerCV")
      docs.cv = {
        id: "scannerCV",
        name: file.name,
        file: `data:application/pdf;base64,${file.content}`,
      };
  });
  return docs;
};

export const noErrorFoundInForm = (element) => {
  let requiredFields = element.querySelectorAll(`[data-required="true"]`);
  let canMove = true;
  requiredFields.forEach((field) => {
    if (field.value === "") canMove = false;
  });
  return canMove;
};
