import { createRouter, createWebHistory } from "vue-router";
const ApplicanceForm = () =>
  import(/*webpackname: "register"*/ "@/views/ApplicanceForm.vue");
const UserUtils = () =>
  import(/* webpackname: "userUtils" */ "@/views/UserUtils");
const PasswordConfirm = () =>
  import(/* webpackname: "passwordConfirm" */ "@/views/PasswordConfirm");
const ThankYou = () => import(/* webpackname: "thankYou" */ "@/views/ThankYou");
const HomeView = () => import(/* webpackname: "home" */ "@/views/HomeView");
const LoginPage = () => import(/* webpackname: "login" */ "@/views/LoginPage");
const PaymentProve = () =>
  import(/*webpackname: "paymentProve" */ "@/views/PaymentProve");
const AproveCandidate = () =>
  import(/*webpackname: "userUtils"*/ "@/views/CandiateAprovation");

import { isTokenExpired } from "@/utils/helper";
const DashboardView = () =>
  import(/*webpackname: "dashboard"*/ "@/views/DashboardView");

export const protectedRoutes = {
  dashboard: "dashboard",
  candidates: "candidates",
  timeline: "timeline",
  candidate: "candidate",
  paymentProve: "paymentProve",
  aproveCandidate: "aproveCandidate",
};

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardView,
  },
  {
    path: "/candidates/:status",
    name: "candidates",
    component: UserUtils,
  },
  {
    path: "/candidate/timeline",
    name: "timeline",
    component: UserUtils,
  },
  {
    path: "/candidate/:id",
    name: "candidate",
    component: ApplicanceForm,
  },
  {
    path: "/candidate/aprove/:id?",
    name: "aproveCandidate",
    component: AproveCandidate,
  },
  {
    path: "/payment-prove/:id",
    name: "editProofs",
    component: PaymentProve,
  },
  {
    path: "/payment-prove",
    name: "paymentProve",
    component: PaymentProve,
  },
  {
    path: "/inscricao",
    name: "register",
    component: ApplicanceForm,
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
  },
  {
    path: "/password-confirm",
    name: "passwordConfirm",
    component: PasswordConfirm,
  },
  {
    path: "/register-confirmation",
    name: "thankYou",
    component: ThankYou,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("refreshToken");
  if (protectedRoutes[to.name] && isTokenExpired(token)) {
    return next({ name: "login" }); // Redirect to login if token is invalid
  } else next(); // Allow navigation if token is valid or route does not require auth
});

export default router;
